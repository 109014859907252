import { Container, CssBaseline, Box, Typography, Divider, Button, Radio, ButtonGroup, Slide, CircularProgress, TextField, Select, MenuItem,
    Checkbox,
    Alert,
    Collapse} from "@mui/material"
    import { LocationOn as Location, MusicNote as Music, Headphones, FaceRetouchingOff as Minor, 
         Checkroom as Clothing, Phone} from "@mui/icons-material"
    import TicketType from "../components/TicketType"
    import { useState } from "react"
    import { useAuthContext } from "../hooks/useAuthContext"
    import { Link } from "react-router-dom"
    import Navbar from "../components/Navbar"
    import { useEffect } from "react"
    import { useNavigate } from "react-router-dom"
    import { ConfirmationNumber as TicketIcon } from "@mui/icons-material"
    
    
    export default function Modify() {
        const navigate = useNavigate();
        const [total, setTotal] = useState(0);
    
        const { user } = useAuthContext();
    
        const [items, setItems] = useState({});
        const eventId = "65a02db585bbff5d44cfa487";
    
    
        const handleTicket = (itemId, quantity, price) => {
            if (quantity > 0) {
                if (itemId in items) {
                    items[itemId].quantity += 1;
                } else {
                    items[itemId] = {itemId, quantity: 1}
                }
                setTotal(total + price)
            } else if (quantity < 0) {
                if (itemId in items) {
                    if (items[itemId].quantity > 1) {
                        items[itemId].quantity -= 1
                        setTotal(total - price)
                    } else if(items[itemId].quantity == 1) {
                        delete items[itemId];
                        setTotal(total - price)
                    }
                }
            }
        }

        const completePurchase = async () => {
            const itemsToPurchase = [];
            for (let key in items) {
                itemsToPurchase.push(items[key]);
            }
            setLoadingButton(true);
            const response = await fetch('https://partyapp-404300.lm.r.appspot.com/api/cart/items', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                },
                body: JSON.stringify({items: itemsToPurchase, eventId: eventId})
            })
            if (response.ok) {
                navigate('/cart', { replace: true });
                setLoadingButton(false);
            } else {
                const json = await response.json();
                setLoadingButton(false);
                setErrorMessage(json.error);
                setShowError(true);
                setTimeout(() => {
                    setShowError(false);
                }, 2500);
                setTimeout(() => {
                    setErrorMessage(null);
                }, 2700);
            } 
        }
        const [loadingButton, setLoadingButton] = useState(false);
    
        const [errorMessage, setErrorMessage] = useState(null);
        const [showError, setShowError] = useState(false);
        const [successMessage, setSuccessMessage] = useState(null);
        const [showSuccess, setShowSuccess] = useState(false);
    
        const [loading, setLoading] = useState(false);
    
        const [tickets, setTickets] = useState([]);
    
        const fetchEvent = async() => {
            setLoading(true);
            const response = await fetch('https://partyapp-404300.lm.r.appspot.com/api/event/', {
                headers: {'eventid': eventId}
            });
            const json = await response.json();
            if (response.ok) {
                const information = json.information;
                for (let item of information.items) {
                    if (item.category == "ticket") {
                        setTickets(tickets => [...tickets, item]);
                    }
                }
            } else {
                console.log(json.erorr);
            }
            setLoading(false);
        }
    
        useEffect(() => {
            const fetch = async() => {
                await fetchEvent();
            }
            fetch();
        }, [])
    
    
    
    
        return (
            <>
                <Navbar/>
                <CssBaseline/>
                {loading && 
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", height: "65vh"}}>
                   <CircularProgress/>
                </Box>}
                {!loading && <>
                <Box
                sx={{
                    position: "fixed",
                    bottom: "90px",
                    right: "5px",
                    zIndex: "100",
                    maxWidth: "90%"
                }}
                >
                    <Slide direction="left" in={showSuccess && (successMessage != null)}>
                        <Alert>{successMessage}</Alert>
                    </Slide>
                </Box>
                <Box
                sx={{
                    position: "fixed",
                    bottom: "90px",
                    right: "5px",
                    zIndex: "100",
                    maxWidth: "90%"
                }}
                >
                    <Slide direction="left" in={showError && (errorMessage != null)}>
                        <Alert severity="error">{errorMessage}</Alert>
                    </Slide>
                </Box>
                <Box
                sx={{
                }}>
                    <Box
                    sx={{
                        paddingTop: "50px", paddingBottom: "100px",
                    }}
                    >
                        <Container maxWidth="lg" 
                        sx={{
                            display: "flex", flexDirection: "column", gap: "20px", minHeight: "100vh"
                        }}>
                            <Divider sx={{bgcolor: "#1a1c1f", width: "100%"}}/>
                            <Box
                            sx={{display: "flex", flexDirection: "column", gap: "10px", width: "100%", textAlign: "center"}}>
                                <Typography variant="h2" fontSize="24px">
                                    Add additional tickets
                                </Typography>
                            </Box>
                            <Divider sx={{bgcolor: "#1a1c1f", width: "100%"}}/>
                            {user && user.isGuest &&
                            <>
                                <Box
                                sx={{display: "flex", flexDirection: "column", gap: "15px", width: "100%"}}
                                > 
                                    <Typography variant="h2" fontSize="24px">
                                        Tickets
                                    </Typography>
                                    <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "100%",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: "400px",
                                        backgroundColor: "#111112",
                                        textAlign: "center"
                                    }}
                                    >
                                        <Typography color="white">
                                            To view and purchase tickets, tables, or extra services, please <Typography component={Link} to="/signup" color="#7102a1" fontWeight="600">sign up</Typography>.
                                        </Typography>
                                    </Box>
                                </Box>
                            </>
                            }
                            {user && !user.isGuest &&
                            <>
                            <Box
                                sx={{backgroundColor: 'transparent', display: "flex", flexDirection: "column", justifyContent: 'center', gap: "15px", width: '100%'}}
                            >   
                                <Typography color="white" fontWeight="600" fontSize="24px">Tickets</Typography>
                                {tickets.map((ticket) => (
                                    <TicketType key={ticket.itemId} ticketType={ticket} handleTicket={handleTicket}/>
                                ))}
                                <Box sx={{display: 'flex', width: '100%', justifyContent:'center'}}>
                                </Box>
                            </Box>
                            </>}
                        </Container>
                    </Box>
                </Box>
                {user && !user.isGuest &&
                <Slide direction="up" in={true}>
                    <Box sx={{position: 'fixed', bottom: '0px',backdropFilter: "brightness(0%) blur(60px)", width: '100%', borderTop: 'solid', borderColor: "#1a1c1f", borderRadius: '20px'}}>
                        <Container sx={{ display: "flex", gap: '10px', justifyContent: 'space-between', width: '100%'
                            , padding: '20px'}}>
                            <Typography fontWeight="600" color="white" fontSize="20px">Total: {total} RON</Typography>
                            {/* <Button component={Link} to="/cart" variant="contained" sx={{borderRadius:"10px", textTransform: "none", fontWeight: "600", backgroundColor: "#7102a1", color:"#BEC3CB", width: 'auto',
                            ":hover": {backgroundColor: "#43025F", color: "white"}}}> */}
                            <Box
                            sx={{
                                display: "flex",
                                gap: "10px",
                                alignItems: "center"
                            }}
                            >   
                                {loadingButton &&
                                    <CircularProgress size="1.5rem"/>
                                } 
                                <Button onClick={()=> completePurchase()} variant="contained" sx={{borderRadius:"10px", textTransform: "none", fontWeight: "600", backgroundColor: "#7102a1", color:"#BEC3CB", width: 'auto',
                                ":hover": {backgroundColor: "#43025F", color: "white"}}}>
                                    <Typography fontWeight="600">Continue</Typography>
                                </Button>
                            </Box>
                        </Container>
                    </Box>
                </Slide>
                }
                </>}
            </>
        )
    }