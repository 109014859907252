import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import React from "react";

export default function Partycard(props) {
    const { eventId, eventName } = props
    return (
        <>  
            <Box 
            sx={{
                display: "flex", flexDirection: "column", gap: "7px"
            }}
            >
                { eventName == "GUY GERBER" &&
                <Link to="/party">
                    <Box sx={{
                        width: '300px',
                        height: '350px',
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '25px',
                        backgroundImage: "url('/gerber.jpeg')",
                        backgroundPosition: "bottom", backgroundRepeat: "no-repeat", backgroundSize: "cover"
                    }}/>
                </Link>
                }
                { eventName == "LAZARE" &&
                <Link to="/lazare">
                    <Box sx={{
                        width: '300px',
                        height: '350px',
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '25px',
                        backgroundImage: "url('/lazare.jpeg')",
                        backgroundPosition: "bottom", backgroundRepeat: "no-repeat", backgroundSize: "cover"
                    }}/>
                </Link>
                }
                
                <Box 
                sx={{
                    display: "flex", flexDirection: "column", gap: "0px", alignItems: "center"
                }}
                >
                    <Typography color="white" fontSize="18px" fontWeight="600">{eventName}</Typography>
                    { eventName == "GUY GERBER" &&
                    <React.Fragment>
                        <Typography color="#9CA3AF" fontSize="13px">Saturday • Oct 12th</Typography>
                        <Typography color="#9CA3AF" fontSize="13px">Dracula's Castle, Bran</Typography>
                    </React.Fragment>
                    }
                    { eventName == "LAZARE" &&
                    <React.Fragment>
                        <Typography color="#9CA3AF" fontSize="13px">Friday • Sep 20th</Typography>
                        <Typography color="#9CA3AF" fontSize="13px">HILLHOUSE, Cluj-Napoca</Typography>
                    </React.Fragment>
                    }
                </Box>
            </Box>
        </>
    )
}