import logo from './logo.svg';
import './App.css';
import { ThemeProvider, createTheme } from '@mui/material';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Account from './pages/Account'
import Party from './pages/Party';
import Cart from './pages/Cart';
import SuccessPage from './pages/SuccessPage';
import Modify from './pages/Modify'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import PartyLazare from './pages/PartyLazare';
import Secret from './pages/Secret';

import { useAuthContext } from './hooks/useAuthContext';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLogout } from './hooks/useLogout';
import { useGuestSignup } from './hooks/useGuestSignup';

const theme = createTheme({
  root: {
    color: "white"
  },
  typography: {
    fontFamily: 'DidactGothic',
    h2: {
      color: 'white',
      fontWeight: '600',
      fontSize: '45px'
    },
    h3: {
      color: "white",
      fontSize: '25px',
      fontWeight: "600"
    },
    p: {
      color: "white"
    }
  },
  palette: {
      action: {
          disabled: "#9CA3AF",
          disabledBackground: "#9CA3AF"
      },
      primary: {
          main: "#ffffff",
          light: "#ffffff",
          dark: "#ffffff"
      },
      background: {
        default: "black"
      }
  },
  components: {
      MuiInputLabel: {
          styleOverrides: {
            root: {
              color: "#9CA3AF",
            },      
          }
      },
      MuiInputBase: {
          styleOverrides: {
              input: {
                  color: '#9CA3AF',
              }
          }
      }
  },
});

function App() {
  const { user } = useAuthContext();
  const [isNotLogged, setIsNotLogged] = useState(false);
  const { logout } = useLogout();
  const { guestSignup } = useGuestSignup();

  useEffect(() => {
    const f = async() => {
      if (user) {
          const email = user.email
          const response = await fetch('https://partyapp-404300.lm.r.appspot.com/api/user/guest', {
              method: 'GET',
              headers: {  
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
              }
          });
          // const response = await fetch('/api/user/guest', {
          //     method: 'GET',
          //     headers: {  
          //       'Content-Type': 'application/json',
          //       'Authorization': `Bearer ${user.token}`
          //     }
          // });
          const json = await response.json();
          if (!response.ok) {
              console.log(json.error)
          }
          if (response.ok) {
              user.isGuest = json.isGuest;
              localStorage.setItem('user', JSON.stringify(user));
          } else {
            logout()
          }
      } else if (!localStorage.getItem('user')) {
          await guestSignup();
      }
    }
    f();
  }, [user && user.isGuest])

  return (
    <>
      <ThemeProvider theme={theme}>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={<Home/>}
            />
            <Route
              path="/login"
              element={!user || user.isGuest ? <Login/> : <Navigate to="/"/>}
            />
            <Route
              path="/signup"
              element={!user || user.isGuest ? <Signup/> : <Navigate to="/"/>}
            />
            <Route
              path="/account"
              element={!user || user.isGuest ? <Signup/> : <Account/>}
            />
            <Route
              path="/party"
              element={<Party/>}
            />
            <Route
              path="/lazare"
              element={<PartyLazare/>}
            />
            <Route
              path="/cart"
              element={<Cart/>}
            />
            <Route
              path="/success"
              element={<SuccessPage/>}
            />
            <Route
              path="/secret"
              element={<Secret/>}
            />
            <Route
              path="/modify"
              element={!user || user.isGuest ? <Signup/> : <Modify/>}
            />
            <Route
              path="/forgot-password"
              element={!user || user.isGuest ? <ForgotPassword/> : <Navigate to="/"/>}
            />
            <Route
              path="/reset-password"
              element={!user || user.isGuest ? <ResetPassword/> : <Navigate to="/"/>}
            />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
