import { Container, CssBaseline, Typography, Box, Divider, Button,
    useTheme, useMediaQuery
    } from "@mui/material";
    // import { Navbar } from "../components/Navbar";
    import Partycard from "../components/Partycard";
    import { FavoriteBorder as Follow } from "@mui/icons-material";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
    
    
    export default function Home() {
        const theme = useTheme();
        const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    
        return (
            <>
                <Navbar/>
                <CssBaseline/>
                <Container maxWidth="lg"
                sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "35px",paddingTop: "35px", paddingBottom: "25px",}}
                >
                    {/* <Typography variant="h2" fontSize="30px">Upcoming Parties</Typography>  */}
                    <Partycard eventName="GUY GERBER" />
                    <Partycard eventName="LAZARE" />
                </Container>
                <Footer/>
            </>
        )
    }