import SignUpForm from "../components/SignupForm";
import { CssBaseline, Container  } from "@mui/material";
import Navbar from "../components/Navbar";

export default function SignUp() {
    return (    
        <>
            <CssBaseline/>
            <Container maxWidth="md" sx={{height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center",
        paddingLeft: "24px",
        paddingRight: "24px"}}>
                <SignUpForm/>
            </Container>
        </>
    )
}