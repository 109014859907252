import { Box, Typography, IconButton, CircularProgress } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { useState } from "react";

import { useAuthContext } from '../hooks/useAuthContext'

export default function TicketType(props) {
    // const { user } = useAuthContext();
    const { name, price, currency, itemId, available } = props.ticketType;
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(0);
  
    const handleIncrease = () => {
        props.handleTicket(itemId, 1, price);
        setValue(value + 1);

        // if (!user) {
        //     // setError('You must be logged in');
        //     return;
        // }
        // setLoading(true);
        // const response = await fetch('/api/cart/add', {
        //     method: 'POST',
        //     body: JSON.stringify({itemId: 1, eventId: "655408c57169f91767badfef"}),
        //     headers: {  
        //         'Content-Type': 'application/json',
        //         'Authorization': `Bearer ${user.token}`
        //     }
        // })
        // const timer = setTimeout(() => {
        //     setLoading(false);
        // }, 500);
        // if (response.ok) {
        //     setValue(value + 1);
        //     await props.handleTicket();
        // }
    };
  
    const handleDecrease = () => {
        props.handleTicket(itemId, -1, price);
        if (value > 0) setValue(value-1);
        // if (!user) {
        //     // setError('You must be logged in');
        //     return;
        // }
        // setLoading(true);
        // const response = await fetch('/api/cart/remove', {
        //     method: 'POST',
        //     body: JSON.stringify({itemId: 1, eventId: "655408c57169f91767badfef"}),
        //     headers: {  
        //         'Content-Type': 'application/json',
        //         'Authorization': `Bearer ${user.token}`
        //     }
        // })
        // const timer = setTimeout(() => {
        //     setLoading(false);
        // }, 500);
        // if (response.ok) {
        //     setValue(value - 1);
        //     await props.handleTicket();
        // }
    };


    // Quantity Selector Logic

    return (
        <>
            {available && 
            <Box
            sx={{display: "flex", color: "white", justifyContent: "space-between", width: "100%", paddingLeft: '15px', paddingRight: '15px', paddingTop: '25px', paddingBottom: '25px', 
            borderStyle: 'solid', borderColor: "#1a1c1f", borderRadius: '20px'}}
            >
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", gap: "10px"}}>
                    {loading && <CircularProgress size="15px"/>}
                    <Box
                    sx={{display: "flex", flexDirection: "column", gap: "0px"}}>
                        <Typography fontSize="18px" fontWeight="600">
                            {name}
                        </Typography>
                        <Typography fontSize="12px">
                            {price} {currency}
                        </Typography>
                    </Box>
                </Box>
                <Box
                sx={{display: "flex", gap: "3px"}}>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        columnGap: "30px",
                        alignItems: "center",
                        width: "100%"
                    }}>
                        { loading &&
                        <IconButton disabled variant="outlined" onClick={() => handleDecrease()} fontSize="small"
                        style={{
                            color: "white",
                            padding: "0px"
                        }}
                        >
                            <Remove fontSize="small"/>
                        </IconButton>
                        }
                        { !loading &&
                        <IconButton variant="outlined" onClick={() => handleDecrease()} fontSize="small"
                        style={{
                            color: "white",
                            padding: "0px"
                        }}
                        >
                            <Remove fontSize="small"/>
                        </IconButton>
                        }
                        <Typography fontWeight="700" fontSize="20px" color="white">{value}</Typography>
                        { loading &&
                        <IconButton disabled variant="outlined" onClick={() => handleIncrease()}  fontSize="small"
                        style={{
                            color: "white",
                            padding: "0px"
                        }}
                        >
                            <Add fontSize="small"/>
                        </IconButton>
                        }
                        { !loading &&
                        <IconButton variant="outlined" onClick={() => handleIncrease()}  fontSize="small"
                        style={{
                            color: "white",
                            padding: "0px"
                        }}
                        >
                            <Add fontSize="small"/>
                        </IconButton>
                        }
                    </Box>
                </Box>
            </Box>}
            {!available &&
            <Box
            sx={{display: "flex", color: "white", justifyContent: "space-between", width: "100%", paddingLeft: '15px', paddingRight: '15px', paddingTop: '25px', paddingBottom: '25px', 
            borderStyle: 'solid', borderColor: "#1a1c1f", borderRadius: '20px'}}
            >
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", gap: "10px"}}>
                    {loading && <CircularProgress size="15px"/>}
                    <Box
                    sx={{display: "flex", flexDirection: "column", gap: "0px"}}>
                        <Typography fontSize="18px" fontWeight="600" color="#9CA3AF">
                            {name} - Sold out
                        </Typography>
                        <Typography fontSize="12px">
                            {price} {currency}
                        </Typography>
                    </Box>
                </Box>
            </Box>}
        </>
    )
   
}