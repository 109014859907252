import { CssBaseline, Container, Typography, Stepper, Step, StepButton,
    styled, Box, Divider, StepLabel, StepConnector, stepConnectorClasses, stepLabelClasses, Button, CircularProgress, IconButton } from "@mui/material";
import { useAuthContext } from "../hooks/useAuthContext";
import { useEffect, useState, Fragment } from "react";
import CartItem from "../components/CartItem";
import { ArrowBackIos, ShoppingCart } from "@mui/icons-material";
import Navbar from "../components/Navbar";
import { ArrowBackIosNew } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import SignUpForm from "../components/SignupForm";
import Footer from "../components/Footer";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
   [`&.${stepConnectorClasses.alternativeLabel}`]: {
       top: 10,
       left: 'calc(-50% + 16px)',
       right: 'calc(50% + 16px)',
   },
   [`&.${stepConnectorClasses.active}`]: {
       [`& .${stepConnectorClasses.line}`]: {
       borderColor: '#7102a1',
       },
   },
   [`&.${stepConnectorClasses.completed}`]: {
       [`& .${stepConnectorClasses.line}`]: {
       borderColor: '#7102a1',
       },
   },
   [`& .${stepConnectorClasses.line}`]: {
       borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
       borderTopWidth: 3,
       borderRadius: 1,
   },
}));

const WhiteStepLabel = styled(StepLabel)(() => ({
   [`& .${stepLabelClasses.label}`]: {
       [`&.${stepLabelClasses.completed}`]: {
         color: '#7102a1',
         fontWeight: '600'
       },
       [`&.${stepLabelClasses.active}`]: {
         color: "white",
         fontWeight: '600'
       },
   
       color: "white",
       fontWeight: '600'
   }
}));

const steps = ['Confirm items', 'Sign Up', 'Payment']

export default function Cart() {
    const navigate = useNavigate();


   const { user } = useAuthContext();
   const [items, setItems] = useState([])
   const [total, setTotal] = useState(0);
   const [tax, setTax] = useState(0);
   const [loading, setLoading] = useState(true);


   // Stepper logic
   const [activeStep, setActiveStep] = useState(0);
   const [completed, setCompleted] = useState({});

   const totalSteps = () => {
       return steps.length;
   };

   const completedSteps = () => {
       return Object.keys(completed).length;
   };

   const isLastStep = () => {
       return activeStep === totalSteps() - 1;
   };

   const allStepsCompleted = () => {
       return completedSteps() === totalSteps();
   };

   const handleNext = () => {
        if (!user.isGuest && activeStep == 0){
            setActiveStep((prevActiveStep) => prevActiveStep + 2);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
   }

    const handleBack = () => {
        if (activeStep == 0) {
            navigate('/');
        } else if (activeStep == 2 && !user.isGuest) {
            setActiveStep((prevActiveStep) => prevActiveStep - 2);
        } else if (activeStep == 1 && !user.isGuest) {
            setActiveStep(0);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    }


    const handlePayment = async() => {
        setLoading(true);
        const response = await fetch('https://partyapp-404300.lm.r.appspot.com/api/order/pay', {
        // const response = await fetch('http://localhost:8000/api/order/pay', {
            method: 'POST',
            headers: {'Authorization': `Bearer ${user.token}`}
        });
        // const response = await fetch('/api/order/pay', {
        //     method: 'POST',
        //     headers: {'Authorization': `Bearer ${user.token}`}
        // });
        const json = await response.json();
        if (response.ok) {
            window.location.replace(json.url);
        } else {
            console.log(json.error);
        }
        setLoading(false);
    }

   useEffect(() => {
        const f = async() => {
            setActiveStep(0);
            if (user) {
                await fetchCart();
            }
        }
        if (user && !user.isGuest && activeStep == 1) {
            setActiveStep(activeStep + 1);
        } else {
            f();
        } 
   }, [user])

   const fetchCart = async() => {
        const response = await fetch('https://partyapp-404300.lm.r.appspot.com/api/cart', {
            headers: {'Authorization': `Bearer ${user.token}`, 
            'email': user.email }
        });
        // const response = await fetch('/api/cart', {
        //     headers: {'Authorization': `Bearer ${user.token}`, 
        //     'email': user.email }
        // });
        const json = await response.json();
        if (response.ok) {
            setItems(json.cart.items);
            setTotal(json.cart.total);
            setTax(json.cart.tax)
            setLoading(false);
        } else {
            console.log(json.error);
            setLoading(false);
        }
   }


   const handleChangeTotal = async(price) => {
       await fetchCart();
   }


   return (
       <>
            <Navbar/>
            <CssBaseline/>
           {loading && 
           <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", height: "65vh"}}>
               <CircularProgress/>
           </Box>}
           {!loading && <>
           {items.length == 0 &&
           <Container maxWidth="lg" sx={{display: "flex", flexDirection: "column", paddingTop: "30px", minHeight: "55vh", gap: "30px"}}>
               <Box sx={{display: "flex", flexDirection: "column", gap: "10px"}}>
                   <Typography variant="h2" fontWeight="600" fontSize="24px">
                       Your cart
                   </Typography>
                   <Divider sx={{bgcolor: "#1a1c1f"}}/>
               </Box>
               <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "5px", height: "45vh"}}>
                   <ShoppingCart sx={{color: "#9CA3AF", fontSize: "70px"}}/>
                   <Typography color="white">No items in your cart</Typography>
               </Box>
           </Container>}
           {items.length > 0 &&
           <>
               <Container maxWidth="lg" sx={{paddingTop: "30px", paddingLeft: "24px", paddingRight: "24px"}}>
                   <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector/>}> 
                       {steps.map((label, index) => {
                           return (
                               <Step key={label} sx={{color: "white"}}>
                                   <WhiteStepLabel>{label}</WhiteStepLabel>
                               </Step>
                           );
                       })} 
                   </Stepper>
               </Container>
               {activeStep == 0 && 
               <Fragment>      
                   <>
                       <Container maxWidth="lg" sx={{display: "flex", flexDirection: "column", paddingTop: "30px", minHeight: "55vh", justifyContent: "space-between", gap: "30px"}}> 
                           <Box sx={{display: "flex", flexDirection: "column", gap: "30px"}}>
                               <Box sx={{display: "flex", flexDirection: "column", gap: "25px", border: "solid", borderColor: "#1a1c1f", padding: "19px", borderRadius: "22px"}}>
                                   <Box sx={{display: "flex", flexDirection: "column", gap: "10px"}}>
                                       <Typography variant="h2" fontWeight="600" fontSize="24px">
                                           Your cart
                                       </Typography>
                                       <Divider sx={{bgcolor: "#1a1c1f"}}/>
                                   </Box>
                                   {/* <Box sx={{display: "flex", flexDirection: "column"}}> */}
                                   {items.length > 0 && 
                                       <>
                                           {
                                               items.map((item, index) => (
                                                   <CartItem key={index} item={item} changeTotal={handleChangeTotal}/>
                                               ))
                                           }
                                       </>
                                   }
                               </Box>
                           </Box>
                           <Box sx={{display: "flex", flexDirection: "column", gap: "25px", justifyContent: "center"}}>
                               <Typography color="white" fontSize="14px" >
                                   Subtotal: {total}RON
                               </Typography>
                               <Typography color="white" fontSize="14px">
                                   Taxes: {tax}RON
                               </Typography>
                               <Divider sx={{bgcolor: "#1a1c1f"}}/> 
                               <Typography color="white" fontSize="18px" fontWeight="600">
                                   Total: {total+tax}RON 
                               </Typography>
                               <Box sx={{display: "flex", gap: "5px", width: "100%"}}>
                                <Button variant="outlined" onClick={() => handleBack()} mt={3} sx={{borderRadius: "20px", width: "50%", fontWeight: "600"}}>
                                    Back
                                    </Button>
                                <Button onClick={() => handleNext(activeStep)} mt={3} variant="contained" sx={{borderRadius: "20px", width: "50%", fontWeight: "600"}}>
                                    Continue
                                </Button>
                               </Box>  
                           </Box>
                       </Container>
                       <Footer/>
                   </>
               </Fragment>}
               {activeStep == 1 && user.isGuest &&
               <Fragment>
                   <Container maxWidth="lg" sx={{display: "flex", flexDirection: "column", paddingTop: "30px", paddingBottom: "30px", minHeight: "55vh", alignItems: "center", gap: "10px"}}> 
                       <Typography color="white" fontSize="22px" fontWeight="600" textAlign="center">
                           Please sign up to complete your order
                       </Typography>
                       <SignUpForm/>
                       <Container maxWidth="md" sx={{display: "flex", gap: "5px", width: "100%", justifyContent: "center"}}>
                            <Button variant="outlined" onClick={() => handleBack()} mt={3} sx={{borderRadius: "20px", width: "50%", fontWeight: "600"}}>
                                Back
                            </Button>
                        </Container>  
                   </Container>
               </Fragment>}
               {activeStep == 2 && !user.isGuest &&
               <Fragment>
                   <Container maxWidth="lg" sx={{display: "flex", flexDirection: "column", paddingTop: "30px", paddingBottom: "30px", minHeight: "55vh", alignItems: "center", gap: "30px"}}> 
                       <Box sx={{display: "flex", flexDirection: "column", gap: "25px", justifyContent: "center"}}>
                           <Typography color="white" fontSize="22px" fontWeight="600" textAlign="center">
                               To complete your purchase, press on the button below to get redirected to Stripe
                           </Typography>
                           <Box sx={{display: "flex", gap: "5px", width: "100%"}}>
                            <Button onClick={() => handleBack()} mt={3} variant="outlined" sx={{borderRadius: "20px", width: "50%", fontWeight: "600"}}>
                                Back
                            </Button>
                            <Button onClick={() => handlePayment()} mt={3} variant="contained" sx={{borderRadius: "20px", width: "50%", fontWeight: "600"}}>
                                Complete Purchase
                            </Button>
                           </Box>
                       </Box>
                   </Container>
                   <Footer/>
               </Fragment>}
           </>}
           </>}
           
       </>
   )}


